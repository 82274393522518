import React from 'react';
import IndexBlogs from "../templates/blogs/index.js";
import DetallesBlogs from "../templates/blogs/detalles";

export default function Blogs(props) {
    const path = props.params['*'];
    console.log("Controlador Blog: ", path)
    if (path && path.match(/([1-9][0-9]*)/)) {
        return <DetallesBlogs {...props} />;
    } else {
        return <IndexBlogs {...props} />;
    }
}