import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { withPrefix } from "gatsby";

import BlogCard from "./BlogCard";

import { ShimmerBlogCargando } from "../widgets/miscelaneos";

export default function CatalogoLayoutBlogs(props) {
    
    const { post, isLoading } = props;

    if(isLoading) {
        return(
            <ShimmerBlogCargando/>
        )
    }

    if (!post || post.length === 0) {
        return (
        <div
            style={{
            textAlign: "center",
            minHeight: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
            }}
        >
            <h5>No se encontraron autos.</h5>
        </div>
        );
    }


    const pintaBlogsCard = post.map((obj, i) => {
        return(
            <Col key={i} px={2} xs={12} className="mb-3 px-2">
                <BlogCard
                   post={obj}
                   image={obj.image}
                   title={obj.title}
                   date={obj.date}
                   description={obj.description} 
                   url={obj.url}
                   marca={props.marca}
                />
            </Col>
        )
    })


    return(
        <>
            <Row  className="px-2">
                {pintaBlogsCard}
            </Row>
        </>
    )

}